/* formatando o cabeçalho dos formulários */
h1 {
  font-size: 25px;
  color: rgba(0, 0, 0, 0.6);
  padding: 10px 0;
  font-family: Arial, sans-serif;
  font-weight: bold;
  text-align: center;
  padding-bottom: 35px;
}

.SNome {
  position: relative;
  left: 2rem;
}

p {
  margin-bottom: 15px;
}

p:first-child {
  margin: 0px;
}

label {
  color: #405c60;
  position: relative;
  text-align: -webkit-center;
  display: block;
}

::-webkit-input-placeholder {
  color: #bebcbc;
  font-style: italic;
}

input:-moz-placeholder,
textarea:-moz-placeholder {
  color: #bebcbc;
  font-style: italic;
}

input {
  outline: none;
}

input:not([type="checkbox"]) {
  width: 30%;
  margin-top: 4px;
  padding: 10px;
  border: 1px solid #b2b2b2;
  border-radius: 3px;
  box-shadow: 0px 1px 4px 0px rgba(168, 168, 168, 0.6) inset;
  transition: all 0.2s linear;
  margin-right: auto;
  margin-left: auto;
  display: block;
}

/* estilo do botão submit */
.txtButton[type="submit"] {
  width: 20% !important;
  cursor: pointer;
  background: rgba(0, 0, 0, 0.6);
  padding: 8px 5px;
  color: #fff;
  font-size: 20px;
  border: 1px solid #fff;
  margin-top: 1rem;
  margin-bottom: 35px;
  text-shadow: 0 1px 1px #333;
  border-radius: 5px;
  transition: all 0.2s linear;
  margin-right: auto;
  margin-left: auto;
  display: block;
}

.txtButton[type="submit"]:hover {
  background: rgb(0 0 0 / 43%);
}

/* tablets */
@media (max-width: 768px) {
  input:not([type=checkbox]) {
      width: 50%;
  }

  .txtButton[type=submit] {
      width: 40% !important;
  }
}

/* celulares */
@media (max-width: 480px) {
  input:not([type=checkbox]) {
      width: 80%;
  }

  .txtButton[type=submit] {
      width: 60% !important;
  }
}

/* estilo do banner de consentimento */
.location-consent-banner {
  background-color: #f9f9f9;
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
  max-width: 500px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  z-index: 1000;
}

.location-consent-banner p {
  margin-bottom: 10px;
  font-size: 14px;
}

.location-consent-banner button {
  background-color: #000000;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.location-consent-banner button:hover {
  background-color: #222222;
  transition: 0.5s;
}